<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-11 15:46:37
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-14 18:39:15
 * @FilePath: \official_website\src\mobile\pages\products\details\Telepathology.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 远程病理诊断解决方案  -->
<template>
  <div id="telepathology">
    <Header></Header>
    <div class="telepathology-img">
       <Breadcrumb
        :productType="productType"
        :productName="productName"
      ></Breadcrumb>
      <img src="@/assets/image/product-detail/programme-2.png" alt="" />
      <div class="illustrate-box">
        <div class="title wow animate__animated animate__fadeInUp">
          远程病理诊断解决方案
        </div>
        <div class="line"></div>
        <ul class="keywords wow animate__animated animate__fadeInUp">
          <li>搭建成本低</li>
          <li>功能强大</li>
          <li>功能可定制</li>
        </ul>
      </div>
    </div>
    <div class="telepathology-description">
      <p class="description-text wow animate__animated animate__fadeInUp">
        远程病理系统由数字切片扫描终端、服务器和远程会诊软件三个部分组成，可以轻松实现远程会诊、远程教学、移动阅片等功能，达到优质资源下沉共享的目的。构建分级诊疗服务新格局，实现优质资源共享
      </p>
      <ul class="telepathology-icons">
        <li class="wow animate__animated animate__fadeInUp">
          <img src="@/assets/image/product-detail/icon-1.png" alt="" />
          <div class="text">下级医院会诊申请</div>
        </li>
        <li class="wow animate__animated animate__fadeInUp">
          <img src="@/assets/image/product-detail/icon-2.png" alt="" />
          <div class="text">平台会诊提醒</div>
        </li>
        <li class="wow animate__animated animate__fadeInUp">
          <img src="@/assets/image/product-detail/icon-3.png" alt="" />
          <div class="text">上级医院会诊处理</div>
        </li>
        <li class="wow animate__animated animate__fadeInUp">
          <img src="@/assets/image/product-detail/icon-4.png" alt="" />
          <div class="text">报告签发</div>
        </li>
      </ul>
    </div>
    <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../../api/api";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Breadcrumb,
    RelatedProduct,
  },
  data() {
    return {
      productType: "解决方案",
      productName: "远程病理诊断解决方案",
      relatProd: [
        {
          title: "全自动特殊染色机",
          src: require("@/assets/image/product/film-4.png"),
          path: "/product/special",
        },
        {
          title: "DNA定量分析诊断试剂",
          src: require("@/assets/image/product/reagent-2.png"),
          path: "/product/dnaReagent",
        },
        {
          title: "DNA倍体定量分析系统",
          src: require("@/assets/image/product/diagnosis-1.png"),
          path: "/product/dnaProd",
        },
        {
          title: "扫描通量600片",
          src: require("@/assets/image/product/digit-1.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量120片",
          src: require("@/assets/image/product/digit-2.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量30片",
          src: require("@/assets/image/product/digit-3.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量2片",
          src: require("@/assets/image/product/digit-4.png"),
          path: "/product/gemini",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;

              console.log(this.relatProd);
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#telepathology {
  padding-top: 6.5rem;
  .telepathology-img {
    img {
      width: 100%;
    }
    .illustrate-box {
      padding: 1.25rem;
      .title {
        font-size: 1.5rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1.5rem;
      }
      .line {
        width: 4.25rem;
        height: 3px;
        margin-bottom: 1.25rem;
        background: #177a73;
      }
      .keywords {
        display: flex;
        li {
          width: 30%;
          height: 2rem;
          line-height: 2rem;
          margin-right: 4%;
          color: #fff;
          background: #177a73;
          border-radius: 2rem;
          text-align: center;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  .telepathology-description {
    padding: 1.25rem;
    width: 100%;
    box-sizing: border-box;
    background-image: url("../../../../assets/image/product-detail/des-bg.png");
    background-size: cover;
    .description-text {
      font-size: 1rem;
      line-height: 2rem;
      margin-bottom: 2rem;
      font-family: "OPlusSans3-Regular";
    }
    .telepathology-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 28%;
        margin-right: 20%;
        margin-bottom: 1.5rem;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        img {
          width: 100%;
          margin-bottom: 0.8rem;
        }
        .text {
          font-size: 0.875rem;
          text-align: center;
          font-family: "SourceHanSansSC-Medium";
        }
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>